<template>
  <a-page-header :title="table['name']" />

  <a-table
    :columns="table['header']"
    :data-source="table['data']"
    :pagination="false"
    :rowKey="record => record.key"
    bordered
    size="middle"
  />
</template>

<script>

export default {
  name: 'ESDLTable',
  props: {
    table: Object,
  },
};

</script>

<style>

.annotation-title {
  color: black;
  font-weight: bold;
}

.annotation-explanation {
  border: solid 1px lightgray;
  width: 50%;
  color: gray;
  display: block;
  margin: 10px;
  padding: 10px;
}
</style>