<template>
  <a-input-search v-model:value="searchValue" style="margin-bottom: 8px" placeholder="Search" />
  <a-tree
    :load-data="onLoadData"
    :tree-data="EDRTreeData"
    :show-line="true"
    v-model:expandedKeys="expandedKeys"
    :auto-expand-parent="autoExpandParent"
    v-model:selectedKeys="selectedKeys"
    @select="selectTreeOption"
    @expand="onExpand"
  >
      <template #title="{ title }">
        <span v-if="title.indexOf(searchValue) > -1">
          {{ title.substr(0, title.indexOf(searchValue)) }}
          <span style="color: #f50">{{ searchValue }}</span>
          {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
        </span>
        <span v-else>{{ title }}</span>
      </template>
  </a-tree>
</template>

<script>
// import EDRListSubMenu from './EDRListSubMenu.vue'
import { API_HOST } from "../envconfig"
import axios from 'axios'
import { TreeDataItem } from 'ant-design-vue/es/tree/Tree';
// import { useEDRCategory } from '../composables/EDRCategory';
import { useEDRTree } from '../composables/EDRTree';

export default {
  name: 'EDRTree',
  props: {
    category: String,
    id: String,
    admin: Boolean,
  },
  components: {
    // 'sub-menu': EDRListSubMenu,
  },
  setup() {
    const { EDRTreeData, addNode, selectedKeys } = useEDRTree();
    return { EDRTreeData, addNode, selectedKeys };
  },
  emits: ['update:id', 'adminInfo'],
  data() {
    return {
      cat: this.category,

      expandedKeys: [],
      searchValue: "",
      EDRTreeDataList: [],
      autoExpandParent: true
    };
  },
  watch: {
    category(val) {
      // console.log(val);
      this.getEDRList(val);
    },
    searchValue(val) {
      let expanded = [];  // if user clears the search box collapse everything
      if (val != "") {
        expanded = this.EDRTreeDataList
          .map(item => {
            if (item.title.indexOf(val) > -1) {
              return this.getParentKey(item.key, this.EDRTreeData);
            }
            return null;
          })
          .filter((item, i, self) => item && self.indexOf(item) === i);
      }
      this.expandedKeys = expanded;
      this.searchValue = val;
      this.autoExpandParent = true;
    }
  },
  mounted() {
    // console.log(this.category);
    this.getEDRList(this.category);
  },
  methods: {
    getEDRList: function(category) {
      const path = `${API_HOST}/api/edr_list?category=` + category;
      // console.log(path);
      axios.get(path)
        .then((res) => {
          // console.log(res);
          this.treeData = res['data'];
          this.EDRTreeData = res['data'];

          this.EDRTreeDataList = [];  // Make sure to start with an empty list
          this.generateList(this.EDRTreeData);

          this.isLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    onLoadData: function(treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }

        const path = `${API_HOST}/api/edr_list/path?id=` + this.category;
        // console.log(path);
        axios.get(path)
          .then((res) => {
            // console.log(res);
            treeNode.dataRef.children = res['data'];
            this.treeData = [...this.treeData];
            this.EDRTreeData = res['data'];

            this.EDRTreeDataList = [];  // Make sure to start with an empty list
            this.generateList(this.EDRTreeData);

            this.isLoading = false;
            resolve();
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      });
    },
    selectTreeOption: function(selectedKeys, e) {
      // console.log(selectedKeys);
      // console.log(e);
      if (!this.admin && e.node.isLeaf) {
        // Only emit an id when user clicks on a leaf node in the tree
        this.$emit('update:id', selectedKeys[0]);
      } else if (this.admin) {
        // In admin mode always emit
        this.$emit('update:id', selectedKeys[0]);
        this.$emit('adminInfo', {
          keys: selectedKeys[0],
          is_leaf: e.node.isLeaf === undefined ? false : true
        });
      }
    },
    generateList: function(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const key = node.key;
        this.EDRTreeDataList.push({
          key,
          title: key,
        });
        if (node.children) {
          this.generateList(node.children);
        }
      }
    },
    onExpand: function(keys) {
      this.expandedKeys = keys;
      this.autoExpandParent = false;
    },
    getParentKey: function(key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some(item => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    }
  },
};
</script>
