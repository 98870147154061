<template>
  <a-page-header v-if="item['object_info'] != undefined"
    :title="item['object_info']['object']['type'] + ': ' + item['object_info']['object']['name']"
  />
  <a-space direction="vertical">
    <div v-if="item['validity_year'] != undefined" style="width: 100%">
      <span style="color:gray">Year: {{ item['validity_year'] }}</span>
    </div>

    <div style="width: 100%">
      <template v-for="(tag, index) in item['tags']" :key="tag">
        <a-tag :class="'tag-color-' + index % 5">
          {{ tag }}
        </a-tag>
      </template>
    </div>

    <a-row>
      <a-col :span="18">
        <a-table v-if="item['object_info'] != undefined"
          :columns="attributes_columns"
          :data-source="item['object_info']['attributes']"
          :pagination="false"
          :rowKey="record => record.name"
          bordered
          size="middle"
        >
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'name'">
              <span title=" {{ record[column.dataIndex].doc }} "> {{ record[column.dataIndex].name }} .</span>
            </template>
            <template v-else>
              . {{ text }}
            </template>
          </template>

        </a-table>

        <ESDLAnnotation :annotation_dict="annotation_dict" title="General explanation" type="GENERAL" />
        <ESDLAnnotation :annotation_dict="annotation_dict" title="Technical dimensions explanation" type="TECHNICAL_DIMENSIONS" />

        <div v-if="item['bld_info'] != undefined">
          <BuildingInfo v-model:building_info="item['bld_info']" />
        </div>

        <div v-if="item['cost_info'] != undefined">
          <a-page-header title="Cost information" />
          <a-table
            :columns="cost_info_columns"
            :data-source="item['cost_info']"
            :pagination="false"
            :rowKey="record => record.name"
            bordered
            size="middle"
          />
        </div>

        <ESDLAnnotation :annotation_dict="annotation_dict" title="Cost explanation" type="COSTS" />

        <div v-if="item['wt_power_curve_info'] != undefined">
          <ESDLTable v-model:table="item['wt_power_curve_info']" />
        </div>

        <div v-if="item['asset_svg_info'] != undefined">
          <ESDLAssetSVG v-model:asset_info="item['asset_svg_info']" />
        </div>

        <div v-if="item['compound_asset_svg_info'] != undefined">
          <ESDLCompoundAssetSVG v-model:asset_list="item['compound_asset_svg_info']" />
        </div>

        <div v-if="item['behaviour_info'] != undefined">
          <ESDLAssetBehaviour v-model:behaviour_info="item['behaviour_info']" />
        </div>

        <ESDLAnnotation :annotation_dict="annotation_dict" title="Energy flows explanation" type="ENERGY_FLOWS" />
        <ESDLAnnotation :annotation_dict="annotation_dict" title="Material flows explanation" type="MATERIAL_FLOWS" />
        <ESDLAnnotation :annotation_dict="annotation_dict" title="Emissions explanation" type="EMISSIONS" />
        <ESDLAnnotation :annotation_dict="annotation_dict" title="Emissions explanation" type="OTHER" />

        <div v-if="item['material_info'] != undefined">
          <ESDLAssetMaterial v-model:material_info="item['material_info']" />
        </div>

        <div v-if="item['buffer_distance_info'] != undefined">
          <a-page-header title="Buffer distance information" />
          <a-table
            :columns="buffer_distance_columns"
            :data-source="item['buffer_distance_info']"
            :pagination="false"
            :rowKey="record => record.type"
            bordered
            size="middle"
          />
        </div>

        <div v-if="item['compound_sub_assets_info'] != undefined">
          <a-page-header title="CompoundAsset sub assets" />
          <template
            v-for="subasset in item['compound_sub_assets_info']"
            :key="subasset['object']['id']"
          >
            <a-page-header :title="subasset['object']['type'] + ': ' + subasset['object']['name']" />
            <a-table
              :columns="attributes_columns"
              :data-source="subasset['attributes']"
              :pagination="false"
              :rowKey="record => record.value"
              bordered
              size="middle"
            />
          </template>
        </div>
      </a-col>
      <a-col v-if="item['image_info'] != undefined" :span="6">
        <img
          class="asset-image"
          :src="'data:' + item['image_info']['contentType'] + ';base64,' + item['image_info']['imageData']"
          style="padding: 10px;"
        />
      </a-col>
    </a-row>
  </a-space>
</template>

<script>
import ESDLAssetSVG from './ESDLAssetSVG.vue'
import ESDLCompoundAssetSVG from './ESDLCompoundAssetSVG'
import ESDLAssetBehaviour from './ESDLAssetBehaviour.vue'
import ESDLAssetMaterial from './ESDLAssetMaterial.vue'
import ESDLAnnotation from './ESDLAnnotation.vue'
import ESDLTable from './ESDLTable.vue'
import BuildingInfo from "@/components/BuildingInfo";

const attributes_columns = [
  {
    key: 'Parameter',
    title: 'Parameter',
    dataIndex: 'name',
  },
  {
    key: 'Value',
    title: 'Value',
    dataIndex: 'value',
  },
  {
    key: 'Unit',
    title: 'Unit',
    dataIndex: 'unit',
  },
];

const cost_info_columns = [
  {
    key: 'Type',
    title: 'Type',
    dataIndex: 'uiname',
  },
  {
    key: 'Value',
    title: 'Value',
    className: 'column-money',
    dataIndex: 'value',
  },
  {
    key: 'Unit',
    title: 'Unit',
    dataIndex: 'unit',
  }
];

const buffer_distance_columns = [
  {
    key: 'Type',
    title: 'Type',
    dataIndex: 'type',
  },
  {
    key: 'Distance',
    title: 'Distance (m)',
    dataIndex: 'distance',
  },
];

export default {
  name: 'ESDLAsset',
  components: {
    BuildingInfo,
    ESDLAssetSVG,
    ESDLCompoundAssetSVG,
    ESDLAssetBehaviour,
    ESDLAssetMaterial,
    ESDLAnnotation,
    ESDLTable,
  },
  props: {
    id: String,
    obj_info: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      item: this.obj_info,
      attributes_columns,
      cost_info_columns,
      buffer_distance_columns,
    };
  },
  computed: {
    annotation_dict: function() {
      let annotation_dict = {};
      let annotation_info = this.item['annotation_info'];
      // console.log(annotation_info);
      if (annotation_info) {
        for (let i = 0; i < annotation_info.length; i++) {
          annotation_dict[annotation_info[i].type] = annotation_info[i].explanation;
        }
      }
      return annotation_dict;
    }
  },
  watch: {
    obj_info(val) {
      this.item = val;
      // console.log(val);
    }
  },
  mounted() {
    // this.getItem();
    // console.log(this.item);
  },
  methods: {

  },
};
</script>

<style>
th.column-money,
td.column-money {
  text-align: right !important;
}

.asset-image {
  width: 100%
}
</style>
