import { ref } from "vue";

const EDRTreeData = ref();
const selectedKeys = ref();

export function useEDRTree() {
  const _findNode = (data, path) => {
    for (let i=0; i<data.length; i++) {
      if (data[i].key == path) {
        return data[i]
      } else if (path.startsWith(data[i].key)) {
        return _findNode(data[i].children, path);
      }
    }
  }

  const _removeNode = (data, path) => {
    for (let i=0; i<data.length; i++) {
      if (data[i].key == path) {
        data.splice(i, 1);
        return true;
      } else if (path.startsWith(data[i].key)) {
        if (_removeNode(data[i].children, path)) {
          return true;
        }
      }
    }
  }

  const addNode = (path, name) => {
    let node = _findNode(EDRTreeData.value, path);
    node.children.push({
      isLeaf: true,
      key: path + '/' + name,
      title: name,
    });
    selectedKeys.value = [path + '/' + name];
  }

  const removeNode = (path) => {
    _removeNode(EDRTreeData.value, path);
    console.log(path);
    console.log('remove');
    let path_elements = path.split('/');
    path_elements.pop();   // remove last element, the filename
    let dir = path_elements.join('/');
    selectedKeys.value = [dir];
    return dir;
  }

  return {
    EDRTreeData,
    selectedKeys,
    addNode,
    removeNode,
  }
}