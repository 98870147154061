<template>
  <div class="about">
    <p>One moment please...</p>
    <p>If this page doesn't redirect properly, then click on some link</p>
  </div>
</template>

<script setup>
import {onMounted} from 'vue';
import {useRoute} from 'vue-router';
import {useKeycloak} from "../keycloak/authentication";
import router from "../router";
const { login, isAuthenticated } = useKeycloak();

function redirectOrLogin(route) {
  if (isAuthenticated.value) {
    const toPath = route.query.to;
    if (toPath) {
      router.push(toPath);
    } else {
      router.push('/')
    }
  } else {
    login();
  }
}

onMounted(() => {
  const route = useRoute();
  // Give the app a little bit to properly initialize Keycloak's authentication.
  setTimeout(() => redirectOrLogin(route), 1000);
});
</script>

<style scoped>
</style>
