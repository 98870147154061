// Add your Keycloak configuration below.
export default {
//    realm: 'master',
//    url: 'http://localhost:9091/auth',
    realm: 'esdl-mapeditor',
    url: 'https://idm.hesi.energy/auth',
    clientId: 'edr',
    sslRequired: 'external',
    publicClient: true,
}

