<template>
  <a-page-header title="Versions and history" />

  <a-table v-if="version_data != undefined"
    :columns="columns"
    :data-source="version_data"
    :pagination="false"
    bordered
    size="middle"
  >
  </a-table>
</template>

<script>

const columns = [
  {
    key: 'User',
    title: 'User',
    dataIndex: 'user',
  },
  {
    key: 'Date',
    title: 'Date',
    dataIndex: 'date',
  },
  {
    key: 'Message',
    title: 'Message',
    dataIndex: 'message',
  },
];


export default {
  name: 'ESDLVersion',
  components: {
  },
  props: {
    id: String,
    version_info: Object
  },
  data() {
    return {
      columns,      
    }
  },
  computed: {
    item_id: function() { return this.id },
    version_data: function() {
      let data = new Array(this.version_info.length);
      for (let i=0;i<this.version_info.length;i++) {
        let d = {};
        let entry = this.version_info[i];
        d.key = i;
        d.user = entry.user;
        d.date = new Date(entry.timestamp).toLocaleString();
        d.message = entry.commitMessage;
        data[i]=d;
      }
      return data;
    }
  }
};
</script>