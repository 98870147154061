<template>
  <a-page-header
    title="ESDL"
  >
    <template #extra>
      <a-button type="primary" @click="download">Download</a-button>
    </template>
  </a-page-header>
  <ssh-pre
    language="xml"
    label="ESDL"
    copy-button
    :key="componentKey"
  >
    {{ esdl_string }}
  </ssh-pre>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import CopyOutlined from '@ant-design/icons-vue'

export default {
  name: 'ESDLViewer',
  components: {
    SshPre,
    CopyOutlined
  },
  props: {
    esdl_string: String,
    obj_info: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      componentKey: 0,
    }
  },
  computed: {
    file_name: function() {
      console.log(this.obj_info);
      return ('object_info' in this.obj_info ? ('object' in this.obj_info['object_info'] ? this.obj_info['object_info']['name']: ''): '')
    }
  },
  watch: {
    /* eslint-disable no-unused-vars */
    esdl_string(val) {
      this.componentKey += 1;
    }
    /* eslint-enable no-unused-vars */
  },
  methods: {
    download: function() {
      const blob = new Blob([this.esdl_string], { type: 'text/xml' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = this.file_name + '.esdl';
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
};
</script>

<style>
</style>