<template>
  <a-page-header title="Building information" />

  <a-table
    :columns="attributes_columns"
    :data-source="bld_info"
    :pagination="false"
    :rowKey="record => record.name"
    bordered
    size="middle"
  >
    <template #value_renderer="{ record }">
      <span>
        {{ record.value.toFixed(1) }}
      </span>
    </template>
  </a-table>
</template>

<script>

const attributes_columns = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
  },
  {
    key: 'value',
    title: 'Value',
    dataIndex: 'value',
    slots: {
      customRender: 'value_renderer'
    }
  },
  {
    key: 'unit',
    title: 'Unit',
    dataIndex: 'unit',
  },
];

export default {
  name: 'BuildingInfo',
  props: {
    building_info: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      bld_info: this.building_info,
      attributes_columns,
    };
  },
  watch: {
    building_info(val) {
      this.bld_info = val;
      console.log(val);
    }
  },
};
</script>

<style>
</style>
