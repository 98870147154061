<template>
  <div>
    <h1>Unauthorized</h1>
    <div>Hello {{ firstName }} {{ lastName }}</div>
    <div>Your account does not have access to the requested page.</div>
    <div><button @click="logout">Logout</button></div>
  </div>
</template>

<script setup>
import {onBeforeMount, ref} from 'vue';
import {useKeycloak} from "../keycloak/authentication";

const { getKeycloak, logout } = useKeycloak();
const keycloak = getKeycloak();

const firstName = ref('');
const lastName = ref('');

onBeforeMount(() => {
  if (keycloak.tokenParsed) {
    firstName.value = keycloak.tokenParsed.given_name
    lastName.value = keycloak.tokenParsed.family_name
  }
});

</script>

<style scoped>

</style>
