import { ref } from "vue";

const selectedEDRCategory = ref("home");

export function useEDRCategory() {
  const setEDRCategory = (cat) => {
    selectedEDRCategory.value = cat;
  }

  return {
    selectedEDRCategory,
    setEDRCategory,
  }
}