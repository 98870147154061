<template>
  <a-page-header v-if="item['object_info'] != undefined && item['object_info']['object']['name'] != null"
    :title="item['object_info']['object']['name']"
  />

  <a-space direction="vertical" style="width: 100%">
    <div v-if="item['validity_year'] != undefined" style="width: 100%">
      <span style="color:gray">Year: {{ item['validity_year'] }}</span>
    </div>

    <div style="width: 100%">
      <template v-for="(tag, index) in item['tags']" :key="tag">
        <a-tag :class="'tag-color-' + index % 5">
          {{ tag }}
        </a-tag>
      </template>
    </div>

    <div v-if="item['embed_URL'] != ''">
      <iframe :src="item['embed_URL']" width="100%" height="400px" />
    </div>

    <a-page-header title="Profile data" />
    <a-table v-if="item['object_info'] != undefined"
      :columns="attributes_columns"
      :data-source="item['object_info']['attributes']"
      :pagination="false"
      :rowKey="record => record.name"
      bordered
      size="middle"
    >
    </a-table>
  </a-space>
</template>

<script>

const attributes_columns = [
  {
    key: 'Parameter',
    title: 'Parameter',
    dataIndex: 'name',
  },
  {
    key: 'Value',
    title: 'Value',
    dataIndex: 'value',
  },
];

export default {
  name: 'ESDLProfile',
  props: {
    id: String,
    obj_info: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  mounted() {
    console.log(this.item);
  },
  data() {
    return {
      item: this.obj_info,
      attributes_columns,
    };
  },
  watch: {
    obj_info(val) {
      this.item = val;
      console.log(val);
    }
  }
};
</script>