<template>
  <div
    class="admin-dir"
  >
    <h1>{{ id }}</h1>
    <a-space direction="vertical" style="width: 100%">
      <a-row>
        <a-col :span="4">Create subdirectory</a-col>
        <a-col :span="8">
           <a-input v-model:value="dir_name" />
        </a-col>
        <a-button @click="createDirectory" :disabled="!dir_name" type="primary">Create</a-button>
      </a-row>
      <a-row>
        <a-col :span="4">Remove this directory</a-col>
        <a-button danger @click="removeDirectory" >Remove</a-button>
      </a-row>
      <a-row>
        <a-col :span="4">Create new EDD file</a-col>
        <a-button @click="createEDDfile" type="primary">Create</a-button>
      </a-row>
      <a-row>
        <a-col :span="4">Create new profile EDD file</a-col>
        <a-button @click="createProfileEDDfile" type="primary">Create</a-button>
      </a-row>
    </a-space>
  </div>
</template>

<script setup="props, { emit }">
import { ref, defineProps, defineEmits, toRefs } from "vue";
import { useKeycloak } from "../keycloak/authentication";
import { API_HOST } from "../envconfig"

const emit = defineEmits(['create_new_EDD', 'create_new_profile_EDD']);
const props = defineProps({
  id: {
    required: true,
  },
});
// eslint-disable-next-line
const { id } = toRefs(props);

const dir_name = ref("");
const edd_name = ref("");
const profile_edd_name = ref("");

const { getHeaders } = useKeycloak();

// eslint-disable-next-line
const createDirectory = async () => {
  if (dir_name.value) {
    const headers = getHeaders();
    const path = `${API_HOST}/api/admin/create_dir?id=` + encodeURIComponent(id.value) + `&directory_name=` + encodeURIComponent(dir_name.value);
    // console.log(path);
    // console.log(headers);
    const response = await fetch(path, {
      method: 'GET',
      headers: headers
    });
    // console.log("Secured API response", response);
    const body = await response.text();

    if (response.ok) {
      const msg = "Success: " + body
      // console.log(msg);
      alert(msg);
    } else {
      const msg = "Error " + response.status + " " + response.statusText + ": " + body;
      console.error(msg);
      alert(msg);
    }
  }
}

// eslint-disable-next-line
const removeDirectory = async () => {
  if (id.value) {
    const headers = getHeaders();
    const path = `${API_HOST}/api/admin/remove_dir?directory_name=` + encodeURIComponent(id.value);
    // console.log(path);
    // console.log(headers);
    const response = await fetch(path, {
      method: 'GET',
      headers: headers
    });
    // console.log("Secured API response", response);
    const body = await response.text();

    if (response.ok) {
      const msg = "Success: " + body
      console.log(msg);
      // alert(msg);
    } else {
      const msg = "Error " + response.status + " " + response.statusText + ": " + body;
      console.error(msg);
      alert(msg);
    }
  }
}

// eslint-disable-next-line
const createEDDfile = () => {
  emit('create_new_EDD');
}

// eslint-disable-next-line
const createProfileEDDfile = () => {
  emit('create_new_profile_EDD');
}

</script>

<style>

.admin-dir {
  background: #ffffff;
  width: 100%;
  height: 100%
}

</style>