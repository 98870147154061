<template>
  <div
    v-if="is_sectors"
  >
    <a-page-header title="Sectors data" />

    <a-space direction="vertical" style="width: 100%">
      <div v-if="item['validity_year'] != undefined" style="width: 100%">
        <span style="color:gray">Year: {{ item['validity_year'] }}</span>
      </div>

      <div style="width: 100%">
        <template v-for="(tag, index) in item['tags']" :key="tag">
          <a-tag :class="'tag-color-' + index % 5">
            {{ tag }}
          </a-tag>
        </template>
      </div>

      <a-table
        :columns="obj_info['sectors_info']['headers']"
        :data-source="obj_info['sectors_info']['info']"
        :pagination="false"
        :rowKey="record => record.key"
        bordered
        size="middle"
      >
      </a-table>
    </a-space>
  </div>

  <div
    v-if="is_carriers"
  >
    <a-page-header title="Carriers data" />

    <a-space direction="vertical" style="width: 100%">
      <div v-if="item['validity_year'] != undefined" style="width: 100%">
        <span style="color:gray">Year: {{ item['validity_year'] }}</span>
      </div>

      <div style="width: 100%">
        <template v-for="(tag, index) in item['tags']" :key="tag">
          <a-tag :class="'tag-color-' + index % 5">
            {{ tag }}
          </a-tag>
        </template>
      </div>

      <a-table
        :columns="obj_info['carriers_info']['headers']"
        :data-source="obj_info['carriers_info']['info']"
        :pagination="false"
        :rowKey="record => record.key"
        bordered
        size="middle"
      >
      </a-table>
    </a-space>
  </div>

</template>

<script>

export default {
  name: 'ESDLKeyFigures',
  props: {
    id: String,
    obj_info: {},
  },
  data() {
    return {
      item: this.obj_info,
      isLoading: true
    };
  },
  computed: {
    is_sectors: function() { return ("sectors_info" in this.obj_info); },
    is_carriers: function() { return ("carriers_info" in this.obj_info); }
  },
  watch: {
    obj_info(val) {
      this.item = val;
      console.log(val);
    }
  },
};
</script>