<template>
  <a-page-header title="ESDL Asset picture" />

  <svg :width="sizes['svg_width']" :height="sizes['svg_height']" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <rect
      :x="sizes['margin_hor'] + sizes['line_length_inport'] + sizes['port_width']"
      :y="sizes['margin_vert']"
      :width="sizes['asset_width']"
      :height="sizes['asset_height']"
      rx="10"
      :stroke="asset_colors[asset_info.capability]['stroke']"
      :fill="asset_colors[asset_info.capability]['fill']"
      stroke-width="5"
    />
    <text
      :x="sizes['margin_hor'] + sizes['line_length_inport'] + sizes['port_width'] + sizes['asset_width']/2"
      :y="sizes['margin_vert'] + sizes['asset_height']/2"
      dominant-baseline="middle"
      text-anchor="middle"
      font-size="2em"
      font-weight="bold"
    >
      {{ getAbbrevation(asset_info.class) }}
    </text>
    <template v-for="(inPort, index) in asset_info.inPorts" :key="inPort.port_id">
      <line
        :x1="sizes['margin_hor']"
        :y1="port_y[asset_info.inPorts.length][index]"
        :x2="sizes['margin_hor'] + sizes['line_length_inport']"
        :y2="port_y[asset_info.inPorts.length][index]"
        stroke="black"
        stroke-width="1"
      />
      <rect
        :x="sizes['margin_hor'] + sizes['line_length_inport']"
        :y="port_y[asset_info.inPorts.length][index] - sizes['port_height']/2"
        width="10" height="10" stroke="black" fill="white" stroke-width="2"
      />
      <text
        :x="sizes['margin_hor'] + sizes['text_hor_offset']"
        :y="port_y[asset_info.inPorts.length][index] + sizes['text_vert_offset']"
      >
        {{ inPort.port_name }}
      </text>
      <text
        v-if="inPort.ratio_to_mainport"
        :x="sizes['margin_hor'] + sizes['value_hor_offset']"
        :y="port_y[asset_info.inPorts.length][index] + sizes['value_vert_offset']"
      >
        ({{ (1 / inPort.ratio_to_mainport).toFixed(2) }} PJ)
      </text>
    </template>
    <template v-for="(outPort, index) in asset_info.outPorts" :key="outPort.port_id">
      <line
        :x1="sizes['right_side']"
        :y1="port_y[asset_info.outPorts.length][index]"
        :x2="sizes['right_side'] + sizes['line_length_outport']"
        :y2="port_y[asset_info.outPorts.length][index]"
        stroke="black"
        stroke-width="1"
      />
      <rect
        :x="sizes['right_side']"
        :y="port_y[asset_info.outPorts.length][index] - sizes['port_height']/2"
        width="10" height="10" stroke="black" fill="white" stroke-width="2"
      />
      <text
        :x="sizes['right_side'] + sizes['margin_hor'] + sizes['text_hor_offset']"
        :y="port_y[asset_info.outPorts.length][index] + sizes['text_vert_offset']"
      >
        {{ outPort.port_name }}
      </text>
      <text
        v-if="outPort.ratio_to_mainport"
        :x="sizes['right_side'] + sizes['margin_hor'] + sizes['value_hor_offset']"
        :y="port_y[asset_info.outPorts.length][index] + sizes['value_vert_offset']"
      >
        ({{ (1 / outPort.ratio_to_mainport).toFixed(2) }} PJ)
      </text>
    </template>
  </svg>
</template>

<script>

const asset_colors = {
  "Producer": {"stroke":"#6B7A00", "fill": "#93A800"},
  "Consumer": {"stroke":"#477393", "fill": "#649EC9"},
  "Conversion": {"stroke":"#7030A0", "fill": "#B574BA"},
  "Storage": {"stroke":"#950B18", "fill": "#CB1325"},
  "Transport": {"stroke":"#BC9500", "fill": "#FFCB00"},
}

let sizes = {
  "margin_hor": 10,
  "margin_vert": 10,
  "port_text_margin": 10,
  "port_width": 10,
  "port_height": 10,
  "asset_width": 80,
  "asset_height": 80,
  "line_length_inport": 350,
  "line_length_outport": 350,
  "text_vert_offset": -5,
  "text_hor_offset": 5,
  "value_vert_offset": 13,
  "value_hor_offset": 5
}

// determines the y-coordinate of a port, based on the number of InPorts or OutPorts
const port_y = {
  1: [50],
  2: [30, 70],
  3: [30, 50, 70],
  4: [30, 50, 70, 90],
  5: [30, 50, 70, 90, 110],
  6: [30, 50, 70, 90, 110, 130],
  7: [30, 50, 70, 90, 110, 130, 150],
  8: [30, 50, 70, 90, 110, 130, 150, 170],
  9: [30, 50, 70, 90, 110, 130, 150, 170, 190],
  10: [30, 50, 70, 90, 110, 130, 150, 170, 190, 210],
  11: [30, 50, 70, 90, 110, 130, 150, 170, 190, 210, 230],
  12: [30, 50, 70, 90, 110, 130, 150, 170, 190, 210, 230, 250],
}

export default {
  name: 'ESDLAssetSVG',
  props: {
    asset_info: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      ass_info: this.asset_info,
      svg_width: 100,
      svg_height: 100,
      asset_colors: asset_colors,
      port_y: port_y,
      sizes: sizes,
    };
  },
  created() {
    this.updateSizes();
  },
  updated() {
    this.updateSizes();
  },
  methods: {
    getAbbrevation(assetType) {
      return assetType.match(/[A-Z]/g).join('');
    },
    updateSizes() {
      let longest_inport_text = 0;
      for (let i=0; i<this.asset_info.inPorts.length; i++) {
        if (this.asset_info.inPorts[i].port_name.length > longest_inport_text) {
          longest_inport_text = this.asset_info.inPorts[i].port_name.length;
        }
      }
      let longest_outport_text = 0;
      for (let i=0; i<this.asset_info.outPorts.length; i++) {
        if (this.asset_info.outPorts[i].port_name.length > longest_outport_text) {
          longest_outport_text = this.asset_info.outPorts[i].port_name.length;
        }
      }
      sizes['line_length_inport'] = longest_inport_text * 8;
      sizes['line_length_outport'] = longest_outport_text * 8;

      let max_num_in_out_ports = Math.max(this.asset_info.inPorts.length, this.asset_info.outPorts.length);
      if (max_num_in_out_ports > 3) {
        sizes["asset_height"] = 20 + 20 * max_num_in_out_ports;
      }
      sizes['svg_height'] = 2 * sizes['margin_vert'] + sizes['asset_height'];
      sizes['svg_width'] = 2 * sizes['margin_hor'] + sizes['line_length_inport'] + sizes['line_length_outport'] + 2 * sizes['port_width'] + sizes['asset_width'];
      sizes['right_side'] = sizes['margin_hor'] + sizes['line_length_inport'] + sizes['port_width'] + sizes['asset_width'];
    }
  },
  watch: {
    asset_info(val) {
      this.ass_info = val;
      this.updateSizes();
    }
  }
};
</script>

<style>
</style>
