<template>
  <template v-if="source_info != undefined">
    <a-page-header
      v-if="source_info.length == 1"
      title="Source"
    />
    <a-page-header
      v-else
      title="Sources"
    />

    <a-table
      v-for="si in source_info"
      :key="si"
      :columns="columns"
      :data-source="si"
      :pagination="false"
      :rowKey="record => record.name"
      class="source_table"
      bordered
      size="middle"
    >
      <template #ds_attr_contents="{ record }">
        <span
          v-if="record.name != 'Reference'"
        >
          {{ record.value }}
        </span>
        <a
          v-else
          :href="record.value"
          target="#"
        >
          {{ record.value }}
        </a>
      </template>
    </a-table>
  </template>
</template>

<script>

const columns = [
  {
    key: 'Parameter',
    title: 'Parameter',
    dataIndex: 'name',
  },
  {
    key: 'Value',
    title: 'Value',
    dataIndex: 'value',
    slots: {
      customRender: 'ds_attr_contents'
    }
  },
];


export default {
  name: 'ESDLSource',
  components: {
  },
  props: {
    id: String,
    source_info: Object
  },
  data() {
    return {
      columns
    }
  },
  computed: {
    item_id: function() { return this.id },
  }
};
</script>

<style>

.source_table {
  margin-bottom: 20px;
}

</style>