<template>
  <div>
    <ProfileUpload
      v-if="state == 'upload_profile'"
      @profile_uploaded="profileUploaded"
    />
    <div
      v-else-if="state == 'edit'"
    >
      <a-space v-if="num_esdl_entities > 1">
        <a-button @click="handlePrevious" :disabled="current_esdl_entity == 0">Previous</a-button>
      {{ current_esdl_entity + 1 }} / {{ num_esdl_entities }}
        <a-button @click="handleNext" :disabled="current_esdl_entity == num_esdl_entities-1">Next</a-button>
        <a-button type="primary" @click="handleSaveAll">Save all</a-button>
      </a-space>
      <EDDEditor
        :properties="edd_object_info"
        :enable_save_and_remove="num_esdl_entities == 1"
        :id="id"
        :new_item="new_item"
        @update_properties="updateProperties"
        @save="handleSave"
        @remove="handleRemove"
      />
    </div>
    <div
      v-else
    >
      Error in implementation: '{{state}}' does not exist
    </div>
  </div>
</template>

<script setup="props, { emit }">
import {ref, defineProps, defineEmits, toRefs, watchEffect} from "vue";
import axios from 'axios'
import { useKeycloak } from "../keycloak/authentication";
import { API_HOST } from "../envconfig"
// eslint-disable-next-line
import EDDEditor from "./EDDEditor.vue"
// eslint-disable-next-line
import ProfileUpload from "./ProfileUpload.vue"
import { useEDRTree } from '../composables/EDRTree';
const { removeNode } = useEDRTree();

const { getHeaders } = useKeycloak();

const props = defineProps({
  id: String,
  category: String,
  new_item: Boolean,
  profile_item: Boolean,
});
const emit = defineEmits(['item_deleted', 'new_item_created']);

const { id, new_item, profile_item } = toRefs(props);
// const edd_object_info = computed(() => { return edd_object_info_list[current_esdl_entity.value]; })
const edd_object_info = ref();

let edd_object_info_list = [];
const state = ref("undefined");
const profile_data = ref();
const num_esdl_entities = ref(1);   // To accommodate multiple simultaneous profile uploads
const current_esdl_entity = ref(0);

const handlePrevious = () => {
  if (current_esdl_entity.value > 0) {
    current_esdl_entity.value--;
    edd_object_info.value = edd_object_info_list[current_esdl_entity.value].value
  }
}

const handleNext = () => {
  if (current_esdl_entity.value < num_esdl_entities.value-1) {
    current_esdl_entity.value++;
    edd_object_info.value = edd_object_info_list[current_esdl_entity.value].value;
  }
}

const getEDDItem = (id) => {
  const path = `${API_HOST}/api/admin/edd?id=` + encodeURIComponent(id);
  // console.log(path);
  axios.get(path)
    .then((res) => {
      // console.log(res);
      edd_object_info.value = res['data'];
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });
}

// eslint-disable-next-line
const handleSave = () => {
  // This function is called whenever the user clicks on the 'Save' button
  if (profile_item.value) {
    edd_object_info.value["profile_data"] = profile_data.value.value.data;
  }
  uploadEDDData(edd_object_info);
}

const handleSaveAll = () => {
  for (let i=0; i<edd_object_info_list.length; i++) {
    uploadEDDData(edd_object_info_list[i]);
  }
}

const updateProperties = (val) => {
  edd_object_info_list[current_esdl_entity.value].value = val;
}

// eslint-disable-next-line
const handleRemove = () => {
  removeEDDData(edd_object_info.value.id);
  let dir = removeNode(edd_object_info.value.id);
  emit('item_deleted', {
    'new_selected_dir': dir
  });
}

watchEffect(() => {
  if (profile_item.value) {
    if (new_item.value) {
      state.value = "upload_profile"
    } else {
      // TODO: Edwin: Should we get some information here???
      state.value = "edit"
    }
  } else {
    if (new_item.value) {
      edd_object_info.value = {
        title: '',
        description: '',
        esdl: '',
        id: id.value,
        tags: [],
      }
      state.value = "edit";
    } else {
      getEDDItem(id.value);
      state.value = "edit"
    }
  }
});

// TODO: Edwin:  Fix .value.value
const generateProfileESDL = async (profile_data, csv_delimiter, decimal_separator, column_name, quantity, unit_string) => {
  const headers = getHeaders();
  headers['Content-Type'] = 'application/json';
  const path = `${API_HOST}/api/profile/generate_profile_esdl`;

  const data = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      quantity: quantity,
      unit_string: unit_string,
      data: profile_data.value.value.data,
      file_name: profile_data.value.value.file_name,
      csv_delimiter: csv_delimiter.value,
      decimal_separator: decimal_separator.value,
      column_name: column_name.value,
    }),
  };
  const response = await fetch(path, data);
  // console.log("Secured API response", response);
  const body = await response.json();
  if (response.ok) {
    let esdl_list = [];
    for (let i=0; i<body['esdl_string_list'].length; i++) {
      esdl_list.push(btoa(body['esdl_string_list'][i]));
    }
    return {
      name_list: body['name_list'],
      esdl_list: esdl_list,
      embed_url_list: body['embed_url_list'],
    };
  } else {
    const msg = "Error " + response.status + " " + response.statusText + ": " + body;
    console.error(msg);
    alert(msg);
    return {
      esdl_list: [],
      embed_url_list: [],
    };
  }
}

const uploadEDDData = async (edd_object_info) => {
  const headers = getHeaders();
  headers['Content-Type'] = 'application/json';
  const path = `${API_HOST}/api/admin/edd`;
  delete edd_object_info.value['history']; // remove history, as this is not relevant to upload and is not part of the schema

  const data = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(edd_object_info.value),
  };
  const response = await fetch(path, data);
  // console.log("Secured API response", response);
  const body = await response.json();
  if (response.ok) {
    const msg = "Upload successful " + response.status + " " + response.statusText + ": " + body;
    console.log(msg);
    // alert(msg);
    if (new_item.value) {
      let id_elems = edd_object_info.value.id.split('/');
      let name = id_elems.splice(-1)[0];
      let folder = id_elems.join('/');
      emit('new_item_created', {name: name, folder: folder});
    }
  } else {
    const msg = "Error " + response.status + " " + response.statusText + ": " + body;
    console.error("Upload error ", response.status, response.statusText, body);
    alert(msg);
  }
}

// eslint-disable-next-line
const profileUploaded = async (parameters) => {
  profile_data.value = parameters.profile_data;
  let esdl_info = await generateProfileESDL(profile_data, parameters.csv_delimiter, parameters.decimal_separator,
    parameters.column_name, parameters.quantity, parameters.unit_string);

  current_esdl_entity.value = 0
  edd_object_info_list = [];

  for (let i=0; i<esdl_info['esdl_list'].length; i++) {
    let this_id = id.value + '/' + esdl_info['name_list'][i] + '.edd';
    edd_object_info_list.push(ref({
      title: esdl_info['name_list'][i],
      description: '',
      esdl: esdl_info['esdl_list'][i],
      graphURL: esdl_info['embed_url_list'][i],
      id: this_id,
      tags: [],
    }));
  }
  num_esdl_entities.value = edd_object_info_list.length;
  edd_object_info.value = edd_object_info_list[current_esdl_entity.value].value;

  state.value = "edit"
}

// eslint-disable-next-line
const removeEDDData = async (edd_object_id) => {
  const headers = getHeaders();
  headers['Content-Type'] = 'application/json';
  const path = `${API_HOST}/api/admin/edd`;

  const data = {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify({'id': edd_object_id}),
  };
  const response = await fetch(path, data);
  // console.log("Secured API response", response);
  const body = await response.json();
  if (response.ok) {
    const msg = "Delete succsesful " + response.status + " " + response.statusText + ": " + body;
    console.log(msg);
    // alert(msg);
  } else {
    const msg = "Error " + response.status + " " + response.statusText + ": " + body;
    console.log(response);
    alert(msg);
  }
}

</script>

<style>

</style>