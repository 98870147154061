<template>
  <div
    v-if="has_contents"
    class="card-container"
  >
    <a-tabs type="card" @change="callback">
      <a-tab-pane key="tab1" tab="Contents">
        <ESDLAsset v-if="item_category=='Assets'" v-model:id="item_id" v-model:obj_info="esdl_object_info" />
        <ESDLProfile v-if="item_category=='Profiles'" v-model:id="item_id" v-model:obj_info="esdl_object_info" />
        <ESDLKeyFigures v-if="item_category=='Key figures'" v-model:id="item_id" v-model:obj_info="esdl_object_info" />
      </a-tab-pane>
      <a-tab-pane key="versions" tab="Versions" v-if="version_info">
        <ESDLVersions v-model:id="item_id" v-model:version_info="version_info"/>
      </a-tab-pane>
      <a-tab-pane v-if="source_info" key="tab2" tab="Source">
        <ESDLSource v-model:id="item_id" v-model:source_info="source_info"  />
      </a-tab-pane>
      <a-tab-pane key="tab3" tab="ESDL">
        <ESDLViewer v-model:obj_info="esdl_object_info" v-model:esdl_string="esdl_string" />
      </a-tab-pane>
    </a-tabs>
  </div>
  <div v-else>
    <a-card style="height:100%">
      Please select an item from the menu at the left.
    </a-card>
  </div>
</template>

<script>
import axios from 'axios'
import { API_HOST } from "../envconfig"
import ESDLAsset from './ESDLAsset.vue'
import ESDLProfile from './ESDLProfile.vue'
import ESDLKeyFigures from './ESDLKeyFigures.vue'
import ESDLSource from './ESDLSource.vue'
import ESDLViewer from './ESDLViever.vue'
import ESDLVersions from './ESDLVersions.vue'

export default {
  name: 'ESDLItem',
  components: {
    ESDLAsset,
    ESDLProfile,
    ESDLKeyFigures,
    ESDLSource,
    ESDLViewer,
    ESDLVersions
  },
  props: {
    category: String,
    id: String
  },
  data() {
    return {
      esdl_object_info: {}
    };
  },
  computed: {
    item_id: function() { return this.id },
    item_category: function() { return this.category; },
    has_contents: function() { return (Object.keys(this.esdl_object_info).length !== 0) },
    esdl_string: function() { return this.esdl_object_info['esdl_string']; },
    source_info: function() { return this.esdl_object_info['source_info']; },
    version_info: function() { return this.esdl_object_info['version_info']; },
  },
  watch: {
    id(val) {
      if (val != undefined) {
        this.get_ESDL_item(val);
      }
    },
    category(val) {
      // console.log(val);
      if (val) this.esdl_object_info = {};
    }
  },
  methods: {
    callback: function() {
      console.log('callback called');
    },
    get_ESDL_item(id) {
      // console.log(id);
      const path = `${API_HOST}/api/edr_item?id=` + encodeURIComponent(id);
      console.log(path);
      axios.get(path)
        .then((res) => {
          // console.log(res);
          this.esdl_object_info = res['data'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    }
  }
};
</script>

<style>
.card-container {
  background: #f5f5f5;
  padding: 24px;
  height: 100%
}

.card-container > .ant-tabs-card {
  height: 100%;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  height: calc(100% - 50px);
  margin-top: -16px;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

</style>