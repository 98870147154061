<template>
  <div
    class="admin-item"
  >
    <h1>{{ id }}</h1>
    <a-space direction="vertical" style="width: 100%">
      <a-row>
        <a-col :span="3">Profile CSV data</a-col>
        <a-col :span="20">
          <a-upload
            v-model:file-list="profile_files"
            name="file"
            :multiple="false"
            action=""
            @change="handleChangeProfile"
            :before-upload="beforeProfileUpload"
          >
            <a-space>
              <a-button>
                <upload-outlined></upload-outlined>
                Click to Upload
              </a-button>
              <a-spin
                v-if="is_loading"
              />
            </a-space>
          </a-upload>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="3">Preview</a-col>
        <a-col :span="20">
          <a-textarea v-model:value="profile_text" wrap="off" disabled :rows=10 />
        </a-col>
      </a-row>
      <a-row
        v-if="profile_text"
      >
        <a-col :span="3">CSV delimiter</a-col>
        <a-col :span="20">
          <a-radio-group v-model:value="csv_delimiter">
            <a-radio value=";">Semicolon (;)</a-radio>
            <a-radio value=",">Comma (,)</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row
        v-if="profile_text"
      >
        <a-col :span="3">Decimal separator</a-col>
        <a-col :span="20">
          <a-radio-group v-model:value="decimal_separator">
            <a-radio value=".">Point (.)</a-radio>
            <a-radio value=",">Comma (,)</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row
        v-if="csv_column_names.length"
      >
        <a-col :span="3">Column out of the CSV</a-col>
        <a-col :span="20">
          <a-select
            v-model:value="csv_column_name"
            :options="csv_column_names"
            placeholder="Select a column name"
            style="width: 200px"
          />
        </a-col>
      </a-row>
      <a-row
        v-if="csv_column_name"
      >
        <a-col :span="3">Quantity and unit</a-col>
        <a-col :span="20">
          <a-select
            v-model:value="qau"
            :options="qau_list"
            placeholder="Select a quantity and unit"
            style="width: 200px"
          />
        </a-col>
      </a-row>
      <a-button type="primary" :disabled="!(profile_text && qau && decimal_separator)" @click="handleNext">Next</a-button>
    </a-space>
  </div>
</template>

<script setup="props, { emit }">
import { ref, defineProps, defineEmits, toRefs, watchEffect } from "vue";
// eslint-disable-next-line
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { encode, decode } from 'utf8';

const emit = defineEmits(['profile_uploaded']);
const props = defineProps({
  id: String,
  category: String,
  properties: Object,
});
// eslint-disable-next-line
const { id, properties } = toRefs(props);

// const edd_object_info = reactive({});
const profile_text = ref("");
const first_line = ref("");
const profile_data = ref();
const csv_column_name = ref("");
const csv_column_names = ref([]);
const csv_delimiter = ref("");
const decimal_separator = ref("");
const is_loading = ref(false);

const qau = ref("");
const qau_list = ref([
  { value:"Energy_in_Wh", label:"Energy in Wh" },
  { value:"Energy_in_kWh", label:"Energy in kWh" },
  { value:"Energy_in_GWh", label:"Energy in GWh" },
  { value:"Energy_in_MWh", label:"Energy in MWh" },
  { value:"Energy_in_TWh", label:"Energy in TWh" },
  { value:"Energy_in_J", label:"Energy in J" },
  { value:"Energy_in_kJ", label:"Energy in kJ" },
  { value:"Energy_in_MJ", label:"Energy in MJ" },
  { value:"Energy_in_GJ", label:"Energy in GJ" },
  { value:"Energy_in_TJ", label:"Energy in TJ" },
  { value:"Energy_in_perc", label:"Energy in %" },
  { value:"Power_in_W", label:"Power in W" },
  { value:"Power_in_kW", label:"Power in kW" },
  { value:"Power_in_MW", label:"Power in MW" },
  { value:"Power_in_GW", label:"Power in GW" },
  { value:"Power_in_TW", label:"Power in TW" },
  { value:"Power_in_perc", label:"Power in %" },
  { value:"Temperature_in_oC", label:"Temperature in degrees Celsius" },
  { value:"Cost_in_EUR", label:"Cost in Euro" },
]);

const qau_mapping = {
  "Energy_in_Wh": { quantity: "ENERGY", unit_string: "Wh" },
  "Energy_in_kWh": { quantity: "ENERGY", unit_string: "kWh" },
  "Energy_in_GWh": { quantity: "ENERGY", unit_string: "GWh" },
  "Energy_in_MWh": { quantity: "ENERGY", unit_string: "MWh" },
  "Energy_in_TWh": { quantity: "ENERGY", unit_string: "TWh" },
  "Energy_in_J": { quantity: "ENERGY", unit_string: "J" },
  "Energy_in_kJ": { quantity: "ENERGY", unit_string: "kJ" },
  "Energy_in_MJ": { quantity: "ENERGY", unit_string: "MJ" },
  "Energy_in_GJ": { quantity: "ENERGY", unit_string: "GJ" },
  "Energy_in_TJ": { quantity: "ENERGY", unit_string: "TJ" },
  "Energy_in_perc": { quantity: "ENERGY", unit_string: "%" },
  "Power_in_W": { quantity: "POWER", unit_string: "W" },
  "Power_in_kW": { quantity: "POWER", unit_string: "kW" },
  "Power_in_MW": { quantity: "POWER", unit_string: "MW" },
  "Power_in_GW": { quantity: "POWER", unit_string: "GW" },
  "Power_in_TW": { quantity: "POWER", unit_string: "TW" },
  "Power_in_perc": { quantity: "POWER", unit_string: "%" },
  "Temperature_in_oC": { quantity: "TEMPERATURE", unit_string: "oC" },
  "Cost_in_EUR": { quantity: "COST", unit_string: "EUR" },
}

watchEffect(() => {
  if (properties.value) {
    // Object.assign(edd_object_info, properties.value);
    // console.log(edd_object_info);
    console.log(properties.value);
  }
});

watchEffect(() => {
  if (first_line.value && csv_delimiter.value) {
    let cn = first_line.value.split(csv_delimiter.value);
    cn.shift();   // drop first element (datetime column)
    csv_column_names.value = [
      {
        label: '[All columns]',
        value: '[All columns]',
      },
      ...cn.map((name) => {
      return {
        label: name,
        value: name,
      }
    })];
  }
})

// eslint-disable-next-line
const handleNext = () => {
  emit("profile_uploaded", {
    profile_data: profile_data,
    csv_delimiter: csv_delimiter,
    decimal_separator: decimal_separator,
    column_name: csv_column_name,
    quantity: qau_mapping[qau.value]["quantity"],
    unit_string: qau_mapping[qau.value]["unit_string"],
  })
}

// =================================================================================================================
//  Upload profile functionality
// =================================================================================================================
// eslint-disable-next-line
const profile_files = ref([]);

// eslint-disable-next-line
const handleChangeProfile = (info) => {
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }

  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }
};

// eslint-disable-next-line
const beforeProfileUpload = file => {
  return new Promise(() => {
    is_loading.value = true;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      let res = reader.result;
      // eslint-disable-next-line
      let [content_type, data] = res.split(',');
      profile_text.value = decode(atob(encode(data)));

      first_line.value = profile_text.value.split('\n')[0].trim()

      profile_data.value = {
        file_name: file.name,
        data: data,
      };
      is_loading.value = false;
    };

    return false;
  });
}

</script>

<style>

.admin-item {
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.admin-asset-image {
  height: 200px;
}

.tag-color-0 {
  color: red;
}
.tag-color-1 {
  color: blue;
}
.tag-color-2 {
  color: orange;
}
.tag-color-3 {
  color: green;
}
.tag-color-4 {
  color: purple;
}

</style>