<template>
  <a-page-header title="Material" />

  <a-table v-if="mat_info['attrs'] != undefined"
    :columns="attributes_columns"
    :data-source="mat_info['attrs']"
    :pagination="false"
    :rowKey="record => record.name"
    bordered
    size="middle"
  >
  </a-table>

  <a-row v-for="(comp, index) in mat_info['component_list']" :key="comp['attrs']['key']">
    <a-col :span="24">
      <a-row type="flex">
        <a-col :span="2">
          <div style="padding: 12px;">
            <b>Layer {{ index+1 }}</b>
          </div>
        </a-col>
        <a-col :span="22">
          <a-table
            :columns="attributes_columns"
            :data-source="comp['attrs']"
            :pagination="false"
            :rowKey="record => record.name"
            bordered
            size="middle"
          >
          </a-table>
        </a-col>
      </a-row>
    </a-col>
  </a-row>

</template>

<script>

const attributes_columns = [
  {
    key: 'Parameter',
    title: 'Parameter',
    dataIndex: 'name',
    width: 150,
  },
  {
    key: 'Value',
    title: 'Value',
    dataIndex: 'value',
  },
  {
    key: 'Unit',
    title: 'Unit',
    dataIndex: 'unit',
    width: 70,
  },
];

export default {
  name: 'ESDLAssetMaterial',
  props: {
    material_info: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      mat_info: this.material_info,
      attributes_columns,
//      cost_info_columns
    };
  },
  watch: {
    material_info(val) {
      this.mat_info = val;
      console.log(val);
    }
  },
  mounted() {
    // this.getItem();
  },
  methods: {

  },
};
</script>

<style>
</style>
