<template>
  <a-page-header title="Asset behaviour" />

  <div v-for="(behaviour,index) in beh_info" :key="index">
    <span>main port: {{ behaviour['mainPort'] }}</span>

    <a-table v-if="behaviour['relations'] != undefined"
      :columns="attributes_columns"
      :data-source="behaviour['relations']"
      :pagination="false"
      :rowKey="record => record.port_id"
      bordered
      size="middle"
    >
      <template #ratio_renderer="{ record }">
        <span>
          {{ record.ratio.toFixed(3) }}
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>

const attributes_columns = [
  {
    key: 'Port',
    title: 'Port',
    dataIndex: 'port_name',
  },
  {
    key: 'Ratio',
    title: 'Ratio to main port',
    dataIndex: 'ratio',
    slots: {
      customRender: 'ratio_renderer'
    }
  },
];

export default {
  name: 'ESDLAssetBehaviour',
  props: {
    behaviour_info: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      beh_info: this.behaviour_info,
      attributes_columns,
    };
  },
  watch: {
    behaviour_info(val) {
      this.beh_info = val;
      console.log(val);
    }
  },
  mounted() {
    // console.log(this.behaviour_info);
  },
  methods: {

  },
};
</script>

<style>
</style>
