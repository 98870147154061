<template>
  <a-page-header title="ESDL Compound Asset picture" />
  <svg :width="sizes['svg_width']" :height="sizes['svg_height']" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <!-- CompoundAsset box -->
    <rect
      :x="sizes['compound_left']"
      :y="sizes['margin_vert']"
      :width="sizes['compound_asset_width']"
      :height="sizes['compound_asset_height']"
      rx="10"
      :stroke="asset_colors['CompoundAsset']['stroke']"
      :fill="asset_colors['CompoundAsset']['fill']"
      stroke-width="5"
    />

    <!-- CompoundAsset InPorts -->
    <template v-for="(inPort, index) in asset_list.inPorts" :key="inPort.port_id">
      <line
        :x1="sizes['margin_hor']"
        :y1="port_y[asset_list.inPorts.length][index]"
        :x2="sizes['margin_hor'] + sizes['line_length']"
        :y2="port_y[asset_list.inPorts.length][index]"
        stroke="black"
        stroke-width="1"
      />
      <rect
        :x="sizes['margin_hor'] + sizes['line_length']"
        :y="port_y[asset_list.inPorts.length][index] - sizes['port_height']/2"
        :width="sizes['port_width']"
        :height="sizes['port_height']"
        stroke="black" fill="white" stroke-width="2"
      />
      <text
        :x="sizes['margin_hor'] + sizes['text_hor_offset']"
        :y="port_y[asset_list.inPorts.length][index] + sizes['text_vert_offset']"
      >
        {{ inPort.port_name }}
      </text>
    </template>

    <!-- CompoundAsset OutPorts -->
    <template v-for="(outPort, index) in asset_list.outPorts" :key="outPort.port_id">
      <line
        :x1="sizes['compound_right'] + sizes['port_width']"
        :y1="port_y[asset_list.outPorts.length][index]"
        :x2="sizes['compound_right'] + sizes['port_width'] + sizes['line_length']"
        :y2="port_y[asset_list.outPorts.length][index]"
        stroke="black"
        stroke-width="1"
      />
      <rect
        :x="sizes['compound_right']"
        :y="port_y[asset_list.outPorts.length][index] - sizes['port_height']/2"
        :width="sizes['port_width']"
        :height="sizes['port_height']"
        stroke="black" fill="white" stroke-width="2"
      />
      <text
        :x="sizes['compound_right'] + sizes['port_width'] + sizes['text_hor_offset']"
        :y="port_y[asset_list.outPorts.length][index] + sizes['text_vert_offset']"
      >
        {{ outPort.port_name }}
      </text>
    </template>

    <!-- Assets inside CompoundAsset -->
    <template v-for="(asset_info, asset_index) in ass_list.subAssets" :key="asset_info.id">
      <rect
        :x="sizes['compound_left'] + sizes['margin_hor'] + sizes['line_length'] + sizes['port_width'] + asset_index * sizes['asset_spacing']"
        :y="2 * sizes['margin_vert']"
        :width="sizes['asset_width']"
        :height="sizes['asset_height']"
        rx="10"
        :stroke="asset_colors[asset_info.capability]['stroke']"
        :fill="asset_colors[asset_info.capability]['fill']"
        stroke-width="5"
      />
      <text
        :x="sizes['compound_left'] + sizes['margin_hor'] + sizes['line_length'] + sizes['port_width'] + sizes['asset_width']/2 + asset_index * sizes['asset_spacing']"
        :y="2 * sizes['margin_vert'] + sizes['asset_height']/2"
        dominant-baseline="middle"
        text-anchor="middle"
        font-size="2em"
        font-weight="bold"
      >
        {{ getAbbrevation(asset_info.class) }}
      </text>
      <template v-for="(inPort, index) in asset_info.inPorts" :key="inPort.port_id">
        <line
          :x1="sizes['compound_left'] + sizes['margin_hor'] + asset_index * sizes['asset_spacing']"
          :y1="port_y[asset_info.inPorts.length][index]"
          :x2="sizes['compound_left'] + sizes['margin_hor'] + sizes['line_length'] + asset_index * sizes['asset_spacing']"
          :y2="port_y[asset_info.inPorts.length][index]"
          stroke="black"
          stroke-width="1"
        />
        <rect
          :x="sizes['compound_left'] + sizes['margin_hor'] + sizes['line_length'] + asset_index * sizes['asset_spacing']"
          :y="port_y[asset_info.inPorts.length][index] - sizes['port_height']/2"
          :width="sizes['port_width']"
          :height="sizes['port_height']"
          stroke="black" fill="white" stroke-width="2"
        />
        <text
          :x="sizes['compound_left'] + sizes['margin_hor'] + sizes['text_hor_offset'] + asset_index * sizes['asset_spacing']"
          :y="port_y[asset_info.inPorts.length][index] + sizes['text_vert_offset']"
        >
          {{ inPort.port_name }}
        </text>
        <text
          v-if="inPort.ratio_to_mainport"
          :x="sizes['compound_left'] + sizes['margin_hor'] + sizes['value_hor_offset'] + asset_index * sizes['asset_spacing']"
          :y="port_y[asset_info.inPorts.length][index] + sizes['value_vert_offset']"
        >
          ({{ (1 / inPort.ratio_to_mainport).toFixed(2) }} PJ)
        </text>
      </template>
      <template v-for="(outPort, index) in asset_info.outPorts" :key="outPort.port_id">
        <line
          :x1="sizes['compound_left'] + sizes['right_side'] + sizes['port_width'] + asset_index * sizes['asset_spacing']"
          :y1="port_y[asset_info.outPorts.length][index]"
          :x2="sizes['compound_left'] + sizes['right_side'] + sizes['port_width'] + sizes['line_length'] + asset_index * sizes['asset_spacing']"
          :y2="port_y[asset_info.outPorts.length][index]"
          stroke="black"
          stroke-width="1"
        />
        <rect
          :x="sizes['compound_left'] + sizes['right_side'] + asset_index * sizes['asset_spacing']"
          :y="port_y[asset_info.outPorts.length][index] - sizes['port_height']/2"
          :width="sizes['port_width']"
          :height="sizes['port_height']"
          stroke="black" fill="white" stroke-width="2"
        />
        <text
          :x="sizes['compound_left'] + sizes['right_side'] + sizes['port_width'] + sizes['text_hor_offset'] + asset_index * sizes['asset_spacing']"
          :y="port_y[asset_info.outPorts.length][index] + sizes['text_vert_offset']"
        >
          {{ outPort.port_name }}
        </text>
        <text
          v-if="outPort.ratio_to_mainport"
          :x="sizes['compound_left'] + sizes['right_side'] + sizes['port_width'] + sizes['value_hor_offset'] + asset_index * sizes['asset_spacing']"
          :y="port_y[asset_info.outPorts.length][index] + sizes['value_vert_offset']"
        >
          ({{ (1 / outPort.ratio_to_mainport).toFixed(2) }} PJ)
        </text>
      </template>
    </template>
  </svg>
  <a-table
    :columns="asset_abbreviations"
    :data-source="sub_asset_types"
    :pagination="false"
    :rowKey="record => record.type"
    bordered
    size="small"
  />
</template>

<script>

const asset_colors = {
  "Producer": {"stroke":"#6B7A00", "fill": "#93A800"},
  "Consumer": {"stroke":"#477393", "fill": "#649EC9"},
  "Conversion": {"stroke":"#7030A0", "fill": "#B574BA"},
  "Storage": {"stroke":"#950B18", "fill": "#CB1325"},
  "Transport": {"stroke":"#BC9500", "fill": "#FFCB00"},
  "CompoundAsset": {"stroke":"#888888", "fill": "#BBBBBB"},
}

let sizes = {
  "margin_hor": 10,
  "margin_vert": 10,
  "port_text_margin": 10,
  "port_width": 10,
  "port_height": 10,
  "asset_width": 80,
  "asset_height": 80,
  "line_length": 80,
  "text_vert_offset": -5,
  "text_hor_offset": 5,
  "value_vert_offset": 13,
  "value_hor_offset": 5,
  "asset_spacing": 80 + 2 * 10 + 2 * 80 + 20
}

// determines the y-coordinate of a port, based on the number of InPorts or OutPorts
const port_y = {
  1: [50 + 10],
  2: [30 + 10, 70 + 10],
  3: [30 + 10, 50 + 10, 70 + 10],
}

const asset_abbreviations = [
  {
    key: 'Abbreviation',
    title: 'Abbreviation',
    dataIndex: 'abbreviation',
  },
  {
    key: 'Type',
    title: 'Type',
    dataIndex: 'type',
  },
];

export default {
  name: 'ESDLCompoundAssetSVG',
  props: {
    asset_list: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      ass_list: this.asset_list,
      asset_colors: asset_colors,
      port_y: port_y,
      sizes: sizes,
      asset_abbreviations: asset_abbreviations,
      sub_asset_types: [],
    };
  },
  created() {
    let num_assets = this.ass_list.subAssets.length;
    sizes['compound_asset_height'] = 2 * sizes['margin_vert'] + sizes['asset_height'];
    sizes['compound_asset_width'] = 2 * sizes['margin_hor'] + 2 * sizes['line_length'] + 2 * sizes['port_width'] +
      sizes['asset_width'] + (num_assets-1) * sizes['asset_spacing'];
    sizes['svg_height'] = 2 * sizes['margin_vert'] + sizes['compound_asset_height'];
    sizes['svg_width'] = 2 * sizes['line_length'] + 2 * sizes['port_width'] + 2 * sizes['margin_hor'] +
      sizes['compound_asset_width'];

    sizes['compound_left'] = sizes['margin_hor'] + sizes['line_length'] + sizes['port_width'];
    sizes['compound_right'] = sizes['compound_left'] + sizes['compound_asset_width'];
    sizes['right_side'] = sizes['margin_hor'] + sizes['line_length'] + sizes['port_width'] + sizes['asset_width'];

    for (let i=0; i<this.ass_list.subAssets.length; i++) {
      this.sub_asset_types.push({
        abbreviation: this.getAbbrevation(this.ass_list.subAssets[i].class),
        type: this.ass_list.subAssets[i].class,
      });
    }
  },
  methods: {
    getAbbrevation(assetType) {
      return assetType.match(/[A-Z]/g).join('');
    }
  },
};
</script>

<style>
</style>
