<template>
  <a-layout>
    <a-layout-sider
      class="edr-sider"
      width=null
    >
      <EDRTree v-model:id="selectedEDRItem" v-model:category="selectedEDRCategory"/>
    </a-layout-sider>
    <ESDLItem
      class="esdl-item"
      v-model:id="selectedEDRItem"
      v-model:category="selectedEDRCategory"
    />
  </a-layout>
</template>

<script setup="props, { emit }">
import { ref } from 'vue';
import { useEDRCategory } from '../composables/EDRCategory';
// eslint-disable-next-line
import EDRTree from '../components/EDRTree.vue'
// eslint-disable-next-line
import ESDLItem from '../components/ESDLItem.vue'

// eslint-disable-next-line
const selectedEDRItem = ref();
// eslint-disable-next-line
const { selectedEDRCategory } = useEDRCategory();

</script>


<style>

.esdl-item {
  margin: 0;
  height: 100%;
  padding: 24px 24px 24px 24px;
  flex-grow: 1;
}

.edr-sider {
  width: 350px;
  resize: horizontal;
  min-width: 250px;
  max-width: unset;
  flex-basis: unset;
  overflow: auto;
  background: #fff
}

</style>